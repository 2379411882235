var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main col-lg-6 mx-auto",staticStyle:{"margin-top":"100px"}},[(!_vm.session_expired)?_c('b-form',{ref:"resetPassword-form",staticClass:"p-4"},[_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-lg-12 mt-2"},[_c('label',{staticClass:"text-secondary"},[_c('strong',[_vm._v("Change password")])]),_c('br'),((typeof _vm.validate.response.matching != 'undefined' && !_vm.validate.response.matching.val))?_c('div',{staticClass:"error_msg"},[_vm._v(" "+_vm._s(_vm.validate.response.matching.msg)+" ")]):_vm._e(),(_vm.error_msg)?_c('div',{staticClass:"error_msg"},[_vm._v(_vm._s(_vm.error_msg))]):_vm._e(),(_vm.success_msg)?_c('div',{staticClass:"success_msg"},[_vm._v(_vm._s(_vm.success_msg))]):_vm._e()])]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-lg-12"},[_c('label',[_vm._v("New Password"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"input-group mb-2 mr-sm-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reset.password),expression:"reset.password"}],ref:"new_password",staticClass:"form-control fields",class:{
      'is-invalid':
        typeof _vm.validate.response.new_password != 'undefined' &&
        !_vm.validate.response.new_password.val,
    },staticStyle:{"border-right":"none"},attrs:{"type":"password","id":"new_password","placeholder":"New Password","name":"new_password","required":""},domProps:{"value":(_vm.reset.password)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.reset, "password", $event.target.value)},function($event){return _vm.validate.passwordValidation(_vm.reset.password, 'new_password')}]}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text pwd"},[_c('i',{staticClass:"mdi menu-icon toggle-password",class:{'mdi-eye-off': this.isText.new_password, 'mdi-eye': this.isPassword.new_password},attrs:{"toggle":"#password-field"},on:{"click":function($event){return _vm.togglePassword('new_password')}}})])]),(
          typeof _vm.validate.response.new_password != 'undefined' &&
          !_vm.validate.response.new_password.val
        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.validate.response.new_password.msg)+" ")]):_vm._e()])])]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-lg-12"},[_c('label',[_vm._v("Confirm Password"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"input-group mb-2 mr-sm-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"}],ref:"confirm_password",staticClass:"form-control fields",class:{
          'is-invalid':
            typeof _vm.validate.response.confirm_password != 'undefined' &&
            !_vm.validate.response.confirm_password.val,
        },staticStyle:{"border-right":"none"},attrs:{"type":"password","id":"confirm_password","placeholder":"Corfirm Password","name":"confirm_password","required":""},domProps:{"value":(_vm.confirm_password)},on:{"input":[function($event){if($event.target.composing)return;_vm.confirm_password=$event.target.value},function($event){_vm.validate.passwordValidation(_vm.confirm_password, 'confirm_password');
          _vm.validate.matchPassword(_vm.reset.password, _vm.confirm_password)}]}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text pwd"},[_c('i',{staticClass:"mdi menu-icon toggle-password",class:{'mdi-eye-off': this.isText.confirm_password, 'mdi-eye': this.isPassword.confirm_password},attrs:{"toggle":"#password-field"},on:{"click":function($event){return _vm.togglePassword('confirm_password')}}})])]),(
              typeof _vm.validate.response.confirm_password != 'undefined' &&
              !_vm.validate.response.confirm_password.val
            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.validate.response.confirm_password.msg)+" ")]):_vm._e()])])]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-lg-12"},[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":function($event){return _vm.passwordReset($event)}}},[_vm._v("Change Password")])])])]):_c('div',[_c('div',{staticClass:"container text-center"},[_c('svg',{staticClass:"svg-inline--fa fa-exclamation-circle fa-w-16 fa-3x",attrs:{"aria-hidden":"true","width":"5rem","height":"5rem","focusable":"false","data-prefix":"fas","data-icon":"exclamation-circle","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"}})]),_c('h3',{staticClass:"mt-5"},[_vm._v("The link you clicked is no longer valid")]),_c('p',{staticClass:"mt-5"},[_vm._v("You can "),_c('a',{attrs:{"href":"/jobseeker/request_password","target":"_blank"}},[_vm._v("request another")]),_vm._v(" though")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }