<template>
  <div class="main col-lg-6 mx-auto" style="margin-top: 100px;">
   <b-form class="p-4" ref="resetPassword-form" v-if="!session_expired">
    <div class="row form-group">
      <div class="col-lg-12 mt-2">
        <label class="text-secondary"><strong>Change password</strong> </label><br>
          <div class="error_msg" v-if="(typeof validate.response.matching != 'undefined' && !validate.response.matching.val)">
            {{ validate.response.matching.msg }}
          </div>
          <div class="error_msg" v-if="error_msg">{{ error_msg }}</div>
          <div class="success_msg" v-if="success_msg">{{ success_msg }}</div>
      </div>
      </div>
        <div class="row form-group">
        <div class="col-lg-12">
        <label>New Password<span style="color: red">*</span></label>
      <div class="input-group mb-2 mr-sm-2">
      <input 
        type="password"
        id="new_password"
        ref="new_password"
        placeholder="New Password"
        class="form-control fields"
        :class="{
        'is-invalid':
          typeof validate.response.new_password != 'undefined' &&
          !validate.response.new_password.val,
      }"
        name="new_password"
        style="border-right: none;"
        v-model="reset.password"
        required=""
        @input="validate.passwordValidation(reset.password, 'new_password')"
      />
      <div class="input-group-prepend">
            <div class="input-group-text pwd">
              <i toggle="#password-field" :class="{'mdi-eye-off': this.isText.new_password, 'mdi-eye': this.isPassword.new_password}" class="mdi menu-icon toggle-password" @click="togglePassword('new_password')"></i>
          </div>
      </div>
      
      <div
        class="invalid-feedback"
          v-if="
            typeof validate.response.new_password != 'undefined' &&
            !validate.response.new_password.val
          "
        >
          {{ validate.response.new_password.msg }}
        </div>
      </div> 
      </div>
          </div>
            <div class="row form-group">
            <div class="col-lg-12">
            <label>Confirm Password<span style="color: red">*</span></label>
          <div class="input-group mb-2 mr-sm-2">
          <input 
            type="password"
            id="confirm_password"
            ref="confirm_password"
            placeholder="Corfirm Password"
            class="form-control fields"
            :class="{
            'is-invalid':
              typeof validate.response.confirm_password != 'undefined' &&
              !validate.response.confirm_password.val,
          }"
            name="confirm_password"
            style="border-right: none;"
            v-model="confirm_password"
            required=""
            @input="validate.passwordValidation(confirm_password, 'confirm_password');
            validate.matchPassword(reset.password, confirm_password)"
          />
          <div class="input-group-prepend">
                <div class="input-group-text pwd">
                  <i toggle="#password-field" :class="{'mdi-eye-off': this.isText.confirm_password, 'mdi-eye': this.isPassword.confirm_password}" class="mdi menu-icon toggle-password" @click="togglePassword('confirm_password')"></i>
              </div>
          </div>
          <div
            class="invalid-feedback"
              v-if="
                typeof validate.response.confirm_password != 'undefined' &&
                !validate.response.confirm_password.val
              "
            >
              {{ validate.response.confirm_password.msg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-12">
        <button class="btn btn-outline-primary" @click="passwordReset($event)">Change Password</button>
      </div>             
      </div>
    </b-form>
    <div v-else>
      <div class="container text-center">
        <svg aria-hidden="true" width="5rem" height="5rem" focusable="false" data-prefix="fas" data-icon="exclamation-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-exclamation-circle fa-w-16 fa-3x"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg>
        <h3 class="mt-5">The link you clicked is no longer valid</h3>
        <p class="mt-5">You can <a href="/jobseeker/request_password" target="_blank">request another</a> though</p>
      </div>
    </div>
  </div>
</template>

<script>
import validation from '../libraries/validFormat' ;
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
  name: "forgot_password",
  data(){
    return {
      action: new Actions(),
      session_expired: false,
      token: null,
      validate: new validation(),
      isPassword: {
        confirm_password: true,
        new_password: true
      },
      isText: {
        confirm_password: false,
        new_password: false
      },
      reset: {
        password:''
      },
      confirm_password: '',
      can_submit: 0,
      success_msg: '',
      error_msg: '',
      notify: false
    }
  },
  beforeMount(){
    this.token = this.$route.query.token;
    this.getUser();
  },
  methods: {
    togglePassword: function(id) {
      let password = this.$refs[id];
      if (password.getAttribute('type') === 'password') {
        password.setAttribute('type', 'text');
        this.isPassword[id] = false;
        this.isText[id] = true;
      }
      else{
          password.setAttribute('type', 'password');
          this.isPassword[id] = true;
          this.isText[id] = false;
      }
    },
    getUser: function(){
      this.action.getUser(this.token).then(res => {
        console.log(res);
      }).catch(() => {
        this.session_expired = true;
        // console.log('err', err.status)
      })
    },
    passwordReset: function(e) {
        e.preventDefault();
        this.can_submit = 0;
        this.error_msg = '';
        this.success_msg = '';

        if (this.validate.passwordValidation(this.reset.password, "new_password")?.val === false) {
          this.can_submit++;
        }
        if (this.validate.matchPassword(this.reset.password, this.confirm_password)?.val === false) {
          this.can_submit++;
        }
        console.log(this.can_submit);
        if (this.can_submit == 0) {
          // let token = this.$cookies.get("hire-seeker").token;
          let data =  {
            input : this.reset,
            token: this.token
          };
          this.action.forgotPasswordReset(data).then((res) => {
            console.log(res);
            if(res.status) {
              this.success_msg = res.message
              this.$router.push({ path: 'login', query: { reset_success: true }});
            } else {
              this.error_msg = res.error
            }
           }).catch((err) => {
            if(err){
              console.log(err);
              this.error_msg = 'Session expired. Go back and try again';
              this.reset.password = '';
              this.confirm_password = '';
            }
          });
      } 
      }
  }
}
</script>
